import { useEffect, useState } from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

const toArrayWithIds = snapshot => {
  return [
    ...snapshot.docs.map(doc => {
      let data = doc.data();
      if (!data) return null;
      return { ...data, id: doc.id };
    }),
  ];
};

const toObjectWithId = snapshot => {
  let data = snapshot.data();
  if (!data) return null;
  return { ...data, id: snapshot.id };
};

const useInstitutions = (query) => {

  const [status, setStatus] = useState('waiting');
  const [response, setResponse] = useState([]);

  const { limit } = query || {};

  let db = null;

  useEffect(() => {
    db = firebase.firestore();
    setStatus('waiting');
    return () => {
      setStatus('waiting');
      setResponse([]);
    };
  }, []);

  const call = {
    fetch: (id) => {
      setStatus('loading');

      if (id) {
        return new Promise((resolve, reject) => {
          db.collection('institutions').doc(id)  
          .get()
          .then((snapshot) => {
            const data = toObjectWithId(snapshot);
            console.log([data])
            setResponse([data]);
            setStatus('success');
            resolve([data]);
          })
          .catch((error) => {
            console.log(error)
            setResponse([]);
            setStatus('error');
            reject(error);
          });
        });
      }

      return new Promise((resolve, reject) => {
        const ref = db.collection('institutions')
        .orderBy('donations', 'asc')
        .limit(limit || 9999);
        ref.get()
        .then((snapshot) => {
          const data = toArrayWithIds(snapshot);
          console.log(data)
          setResponse(data);
          setStatus('success');
          resolve(data);
        })
        .catch((error) => {
          console.log(error)
          setResponse([]);
          setStatus('error');
          reject(error);
        });
      });
    }
  };

  return [call, { response, status }];

};

export default useInstitutions;
