import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabsHeader = styled.ul`
  display: flex;
`;

const TabsBody = styled.div`
  overflow: auto;
`;

const TabHeader = styled.li`
  cursor: pointer;
`;

const TabContent = styled.div``;

export const Tab = ({
  header,
  index,
  isActive,
  onActive,
  onClick,
  params,
}) => {

  useEffect(() => {
    if (isActive) onActive({ index, ...params });
  }, [isActive]);

  return (
    <TabHeader onClick={onClick} isActive={isActive}>
      {typeof header === 'function' ? header(isActive) : header}
    </TabHeader>
  );
};

Tab.propTypes = {
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  index: PropTypes.number,
  isActive: PropTypes.bool,
  onActive: PropTypes.func,
  onClick: PropTypes.func,
  params: PropTypes.object
};

const Tabs = ({
  children,
  defaultActive,
  onActive,
  wrapperStyle,
  wrapperClassName,
  headerStyle,
  headerClassName,
  bodyStyle,
  bodyClassName
}) => {

  const [current, setCurrent] = useState(null);

  useEffect(() => {
    setCurrent(defaultActive);
  }, [defaultActive]);

  if (current === null) return null;

  const handleActive = onActive;

  const contents = React.Children.map(children, (element, index) => {
    if (element.type.name !== 'Tab') return null;
    return current === index && <TabContent>{element.props.children}</TabContent>;
  });

  return (
    <TabsWrapper style={wrapperStyle} className={wrapperClassName}>
      <TabsHeader style={headerStyle} className={headerClassName}>
        {React.Children.map(children, (element, index) => {
          if (element.type.name !== 'Tab') return null;
          const Component = element.type;
          return (
            <Component
              {...element.props}
              onClick={() => setCurrent(index)}
              onActive={handleActive}
              index={index}
              isActive={current === index}
            />
          );
        })}
      </TabsHeader>
      {contents.length > 0 && (
        <TabsBody style={bodyStyle} className={bodyClassName}>
          {contents}
        </TabsBody>
      )}
    </TabsWrapper>
  );
};

Tabs.defaultProps = {
  defaultActive: 0,
  onActive: () => {}
};

Tabs.propTypes = {
  bodyClassName: PropTypes.string,
  bodyStyle: PropTypes.object,
  children: PropTypes.node.isRequired,
  defaultActive: PropTypes.number,
  headerClassName: PropTypes.string,
  headerStyle: PropTypes.object,
  onActive: PropTypes.func,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object
};

export default Tabs;
