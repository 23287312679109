import { useEffect, useState } from 'react';

const useExcerpt = (string, limit) => {

  const [response, setResponse] = useState({});

  useEffect(() => {
    const raw = string;
    let excerpted = string;
    excerpted = excerpted.substr(0, excerpted.lastIndexOf(' ', limit)) + '...';
    setResponse({
      raw,
      short: excerpted
    });
  }, [string, limit]);

  return response;

};

export default useExcerpt;