import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import useExcerpt from '../../hooks/useExcerpt';
import useStorage from '../../hooks/useStorage';

const Card = (props) => {

  const {
    name,
    description,
    city,
    picturesFolder,
    tags,
    target,
    donations,
    id
  } = props;

  const [getImages, { response }] = useStorage(picturesFolder);

  const { short } = useExcerpt(description, 150);

  useEffect(() => {
    if (picturesFolder) getImages();
  }, [picturesFolder]);

  let maxDonations = null;

  if (target > 0) {
    maxDonations = donations >= target;
  }

  return (
    <div className="card-container">
      <Link to={`instituicao?uid=${id}`} state={{ institution: props }} className="card has-footer-overlaid">
        <div className="card-image">
          <figure className="image is-card-image" style={{ backgroundImage: `url(${response[0]})` }}>
            <img src={response[0]} alt={'Alt'} />
          </figure>
        </div>
        <div className="card-content">
          <div className="wrapper has-tags">
            <span className="text is-size-7 is-light is-uppercase no-wrap">{city}</span>
            {tags.split(',').map((tag, index) => <span className="tag" key={index}>{tag.trim()}</span>)}
            <div className="tags" />
          </div>
          <div className="v-space" />
          <h3 className="title is-size-4">
            {name}
          </h3>
          <div className="v-space" />
          <p className="text is-size-6">
            {short}
          </p>
        </div>
      </Link>
      <footer className="card-footer is-overlaid">
        <Link
          to={!maxDonations ? `cadastro?uid=${id}` : 'instituicoes'}
          state={{ institution: props }}
          disabled={maxDonations}
          className="button is-outlined is-small">
          <span>{!maxDonations ? 'Fazer doação' : 'Doações encerradas'}</span>
        </Link>
      </footer>
    </div>
  );

};

Card.propTypes = {
  id: PropTypes.string
};

export default Card;
