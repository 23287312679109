import React, { useEffect } from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Content from '../components/partials/institutions/Content';
import Hero from '../components/partials/institutions/Hero';

const Institutions = () => {

  useEffect(() => {}, []);

  return (
    <div className="is-inner-page">
      <Layout>

        <SEO title="Instituições" />

        <Hero />
        <Content />

      </Layout>
    </div>
  );

};

export default Institutions;
