import React, { useEffect, useState } from 'react';

import List from './List';
import Filter from './Filter';

import { Tabs, Tab } from '../../lib';

const checkFilter = (filter, type) => {
  const keys = Object.keys(filter[type]);
  return keys.filter((itemKey) => {
    const value = filter[type][itemKey];
    const allValue = `Todas as ${type === 'regions' ? 'regiões' : 'causas'}`;
    return value && itemKey !== allValue;
  });
}

const Content = () => {

  const [filter, setFilter] = useState({
    regions: { 'Todas as regiões': true },
    causes: { 'Todas as causas': true },
  });

  const onChange = (type, value, checked) => {
    const allValue = `Todas as ${type === 'regions' ? 'regiões' : 'causas'}`;
    let update = {
      ...filter,
      [type]: { ...filter[type], [value]: checked }  
    };
    if (checkFilter(update, type).length > 0) update = {
      ...filter,
      [type]: { ...filter[type], [value]: checked, [allValue]: false }
    }
    if (value === allValue || checkFilter(update, type).length === 0) update = {
      ...filter,
      [type]: { [allValue]: true }
    }
    setFilter(update);
  };

  return (
    <section
      id="near"
      className="section has-dots"
      style={{ backgroundImage: `url(${require('../../../images/backgrounds/section-dots-bg.svg')})` }}>
      <div className="container">

        <header className="section-header visible-desktop-true is-filter-header">
          <h1 className="title is-xxlarge">ONGs participantes</h1>
          <div className="v-space-lg" />
          <p className="text">
            Todas as ONGs participantes da Campanha Atitudes do Bem foram cuidadosamente selecionadas pelo Instituto Malwee com o apoio do Movimento Arredondar. Agora é você que escolhe quem recebe a doação!
            <br/>
            <span className="is-highlighted">
              A seleção das doações é com você! 
            </span>
          </p>
          <div className="v-space" />
        </header>

        <Filter onChange={onChange} filter={filter} />

        <div className="visible-desktop-false"><List filter={filter} /></div>
        <div className="visible-desktop-true"><List filter={filter} /></div>

      </div>
    </section>
  );
};

export default Content;