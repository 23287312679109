/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styled, { css, keyframes } from 'styled-components';


const slideUpSoft = keyframes`
  0% {
    opacity: .1;
    transform-origin: center;
    transform: translateY(2%);
  }
  100% {
    opacity: .99;
    transform-origin: center;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  0% { opacity: .1; }
  100% { opacity: .99; }
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
  ${({ isOpen, isCover }) => isOpen ? css`
    height: 100%;
    opacity: 1;
    width: 100%;
    ${Background} {
      opacity: 1;
    }
    ${Content} {
      animation: ${slideUpSoft} ${isCover ? '.3s' : '.1s'} ease-in-out;
      opacity: 1;
    }
  ` : css`
    height: 0;
    opacity: 0;
    width: 0;
  `}
`;

const Content = styled.div`${({ isCover }) => css`
  background-color: #FFF;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: absolute;
  width: ${isCover ? '100%' : 'auto'};
  height: ${isCover ? '100%' : 'auto'};
  z-index: 100;
`}`;

const Body = styled.div`
  flex: 1;
  overflow: auto;
  /* padding: 15px; */
`;

const Background = styled.div`
  animation: ${fadeIn} .3s ease-in-out;
  background-color: rgba(0, 0, 0, .6);
  display: block;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

const ModalBody = ({
  isLightbox,
  isCover,
  isOpen,
  children,
  disableClose,
  onClickToClose,
}) => (
  <Wrapper isOpen={isOpen} isCover={isCover}>
    {isLightbox ? (
      children
    ) : (
      <Content isCover={isCover}>
        <Body>{children}</Body>
      </Content>
    )}
    <Background onClick={!disableClose ? onClickToClose : null} />
  </Wrapper>
);

class Portal extends React.PureComponent {

  body = document.body

  componentDidMount() {
    this.body.appendChild(this.element);
  }
  componentWillUnmount() {
    this.body.removeChild(this.element);
  }
  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return ReactDOM.createPortal(this.props.children, this.element);
  }
  element = document.createElement('div')
};

const Modal = (props) => <Portal><ModalBody {...props} /></Portal>;

Modal.Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

Modal.Content = styled.div`
  flex: 1;
  max-height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

Modal.Footer = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: 1px solid #CCC;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
`;

export default Modal;
